import React from 'react';
import { useTranslation } from 'react-i18next';

const Slider = () => {
    const { t } = useTranslation();
    return (
        <>
        <div id="astron-rooms" className="astron-light-grey" style={{ padding: "6rem 0" }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12  text-center astron-heading animate-box">
                        <span><em style={{ fontSize: "30px" }} className="icon-star-full"></em><em style={{ fontSize: "30px" }} className="icon-star-full"></em><em style={{ fontSize: "30px" }}
                            className="icon-star-full"></em></span>
                        <h1>{t("welcome.header")}</h1>
                        <div className="col-md-4">
                            <h3><img className="bullet" alt="Bullet List" src="images/icons/icons8-checklist-64.png" /> {t("welcome.p1")}</h3>
                        </div>
                        <div className="col-md-4">
                            <h3><img className="bullet" alt="Bullet List" src="images/icons/icons8-checklist-64.png" /> {t("welcome.p2")}</h3>
                        </div>
                        <div className="col-md-4">
                            <h3><img className="bullet" alt="Bullet List" src="images/icons/icons8-checklist-64.png" /> {t("welcome.p3")}</h3>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingBottom: "8rem" }}>
                    <div className="col-md-12 animate-box">
                        <div className="col-md-4 animate-box">
                            <div className="item">
                                <a aria-label='' href="images/services/With-Balcony/1112.JPG" className="room image-popup-link" style={{ color: "black", backgroundImage: "url(images/services/With-Balcony/1112.JPG)" }}
                                ></a>
                                <div className="desc text-center">
                                    <h3><a href="services">{t("welcome.room1")} </a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 animate-box">
                            <div className="item">
                                <a aria-label='' href="images/services/No-Balcony/1113.JPG" className="room image-popup-link" style={{ color: "black", backgroundImage: "url(images/services/No-Balcony/1113.JPG)" }}
                                ></a>
                                <div className="desc text-center">
                                    <h3><a href="services">{t("welcome.room2")} </a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 animate-box">
                            <div className="item">
                                <a aria-label='' href="images/services/Two-Room/1111.JPG" className="room image-popup-link" style={{ color: "black", backgroundImage: "url(images/services/Two-Room/1111.JPG)" }}
                                ></a>
                                <div className="desc text-center">
                                    <h3><a href="services">{t("welcome.room3")} </a></h3>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div></>

    );
};

export default Slider;
