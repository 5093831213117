import React, {useState} from "react";
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import LoaderImages from '../components/LoaderImages';

const Gallery = () => {
  const [roomsLoading, setRoomsLoading] = useState(true);
  const [hotelLoading, setHotelLoading] = useState(true);

  const handleRoomsImageLoad = () => {
    setRoomsLoading(false);
  };

  const handleHotelImageLoad = () => {
    setHotelLoading(false);
  };

  const { t } = useTranslation();

  const roomsImages = [
    {
      original: "images/gallery/Rooms/1111.JPG",
      thumbnail: "images/gallery/Rooms/1111.JPG",
      originalAlt: "Room 1111",
      thumbnailAlt: "Room 1111",
    },
    {
      original: "images/gallery/Rooms/1112.JPG",
      thumbnail: "images/gallery/Rooms/1112.JPG",
      originalAlt: "Room 1112",
      thumbnailAlt: "Room 1112",
    },
    {
      original: "images/gallery/Rooms/1113.JPG",
      thumbnail: "images/gallery/Rooms/1113.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/20200808_124318.jpg",
      thumbnail: "images/gallery/Rooms/20200808_124318.jpg",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/20200808_124347.jpg",
      thumbnail: "images/gallery/Rooms/20200808_124347.jpg",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/20200808_124406.jpg",
      thumbnail: "images/gallery/Rooms/20200808_124406.jpg",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/20200808_124435.jpg",
      thumbnail: "images/gallery/Rooms/20200808_124435.jpg",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9123.JPG",
      thumbnail: "images/gallery/Rooms/img_9123.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9126.JPG",
      thumbnail: "images/gallery/Rooms/img_9126.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9127.JPG",
      thumbnail: "images/gallery/Rooms/img_9127.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9141.JPG",
      thumbnail: "images/gallery/Rooms/img_9141.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9142.JPG",
      thumbnail: "images/gallery/Rooms/img_9142.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9144.JPG",
      thumbnail: "images/gallery/Rooms/img_9144.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9149.JPG",
      thumbnail: "images/gallery/Rooms/img_9149.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9162.JPG",
      thumbnail: "images/gallery/Rooms/img_9162.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9195.JPG",
      thumbnail: "images/gallery/Rooms/img_9195.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },

    {
      original: "images/gallery/Rooms/img_9201.JPG",
      thumbnail: "images/gallery/Rooms/img_9201.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9218.JPG",
      thumbnail: "images/gallery/Rooms/img_9218.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9220.JPG",
      thumbnail: "images/gallery/Rooms/img_9220.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
    {
      original: "images/gallery/Rooms/img_9244.JPG",
      thumbnail: "images/gallery/Rooms/img_9244.JPG",
      originalAlt: "Room 1113",
      thumbnailAlt: "Room 1113",
    },
  ];

  const hotelImages = [
    {
      original: "images/gallery/Hotel/10.JPG",
      thumbnail: "images/gallery/Hotel/10.JPG",
      originalAlt: "Hotel 10",
      thumbnailAlt: "Hotel 10",
    },
    {
      original: "images/gallery/Hotel/11.JPG",
      thumbnail: "images/gallery/Hotel/11.JPG",
      originalAlt: "Hotel 11",
      thumbnailAlt: "Hotel 11",
    },
    {
      original: "images/gallery/Hotel/12.JPG",
      thumbnail: "images/gallery/Hotel/12.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/20180717_195415.jpg",
      thumbnail: "images/gallery/Hotel/20180717_195415.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/20180717_195723.jpg",
      thumbnail: "images/gallery/Hotel/20180717_195723.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9076.JPG",
      thumbnail: "images/gallery/Hotel/img_9076.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9080.JPG",
      thumbnail: "images/gallery/Hotel/img_9080.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9083.JPG",
      thumbnail: "images/gallery/Hotel/img_9083.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9084.JPG",
      thumbnail: "images/gallery/Hotel/img_9084.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9086.JPG",
      thumbnail: "images/gallery/Hotel/img_9086.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9099.JPG",
      thumbnail: "images/gallery/Hotel/img_9099.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9104.JPG",
      thumbnail: "images/gallery/Hotel/img_9104.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9106.JPG",
      thumbnail: "images/gallery/Hotel/img_9106.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9108.JPG",
      thumbnail: "images/gallery/Hotel/img_9108.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9254.JPG",
      thumbnail: "images/gallery/Hotel/img_9254.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9255.JPG",
      thumbnail: "images/gallery/Hotel/img_9255.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9257.JPG",
      thumbnail: "images/gallery/Hotel/img_9257.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9260.JPG",
      thumbnail: "images/gallery/Hotel/img_9260.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Hotel/img_9266.JPG",
      thumbnail: "images/gallery/Hotel/img_9266.JPG",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/1.jpg",
      thumbnail: "images/gallery/Breakfast/1.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/2.jpg",
      thumbnail: "images/gallery/Breakfast/2.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/3.jpg",
      thumbnail: "images/gallery/Breakfast/3.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/4.jpg",
      thumbnail: "images/gallery/Breakfast/4.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/5.jpg",
      thumbnail: "images/gallery/Breakfast/5.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/6.jpg",
      thumbnail: "images/gallery/Breakfast/6.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/7.jpg",
      thumbnail: "images/gallery/Breakfast/7.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/8.jpg",
      thumbnail: "images/gallery/Breakfast/8.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/9.jpg",
      thumbnail: "images/gallery/Breakfast/9.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
    {
      original: "images/gallery/Breakfast/10.jpg",
      thumbnail: "images/gallery/Breakfast/10.jpg",
      originalAlt: "Hotel 12",
      thumbnailAlt: "Hotel 12",
    },
  ];
  return (
    <>
      <div id="astron-gallery" className="astron-light-grey">
        <div className="row">
          <div id="astron-gallery">
            <div className="col-lg-12  animate-box" >
              <h1>{t("gallery.rooms")}</h1>
              <div className="col-md-12 text-center astron-heading animate-box">
              {roomsLoading && <LoaderImages />}
                <ImageGallery
                  stopPropagation
                  items={roomsImages}
                  onImageLoad={handleRoomsImageLoad}
                />
              </div>
            </div>

            <div className="col-lg-12  animate-box" >
              <div className="col-md-12  text-center astron-heading animate-box "
                style={{ marginBottom: "1em", marginTop: "1em" }}>
                <h1>{t("gallery.hotel")}</h1>
                <div className="col-md-12 text-center astron-heading animate-box">
                {hotelLoading && <LoaderImages />}
                <ImageGallery
                  stopPropagation
                  items={hotelImages}
                  onImageLoad={handleHotelImageLoad}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;