import React from "react";
import Carousel from "../partials/Carousel";
import Slider from "../partials/Slider";

const Home = () => {
  return (
    <>
    <Carousel></Carousel>  
    <Slider></Slider>
    </>
  );
};

export default Home;
