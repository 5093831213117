import { combineReducers } from "redux";
import environmentReducer from "./environmentReducer";
// Import other reducers as needed

const rootReducer = combineReducers({
  environment: environmentReducer,
  // Add other reducers here
});

export default rootReducer;
