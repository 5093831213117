import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../components/Header/Header.css";
import { useTranslation } from "react-i18next";

function OverlayMenu() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const booking = "https://www.booking.com/hotel/gr/astron-murina-lemnos.".concat(language).concat(".html");

  return (
    <>
      <div className={`offcanvas-menu-overlay ${isOpen ? "active" : ""}`}></div>
      <div onClick={toggleSidebar} className="canvas-open">
        <i className="icon_menu"></i>
      </div>
      <div
        className={`offcanvas-menu-wrapper ${
          isOpen ? "show-offcanvas-menu-wrapper" : ""
        }`}
      >
        <div className="canvas-close" onClick={closeSidebar}>
          <i className="icon_close"></i>
        </div>
        <div className="header-configure-area">
          <div className="language-option">
            <ul
              style={{
                listStyle: "none",
                cursor: "pointer",
                display: "inline-flex",
              }}
            >
              <li>
                <a
                  style={{
                    borderStyle: "none",
                    backgroundColor: "transparent",
                  }}
                  onClick={() =>
                    changeLanguage(language === "en" ? "gr" : "en")
                  }
                >
                  {language === "en" ? "GR" : "EN"}
                  <img
                    src={`./images/icons/${
                      language === "en" ? "gr" : "en"
                    }.png`}
                    alt=""
                  />
                </a>
              </li>
              <li>
                <span>{language.toUpperCase()}</span>
                <img src={`./images/icons/${language}.png`} alt="" />
              </li>
            </ul>
          </div>
        </div>
        <nav className="slicknav_menu">
          <ul style={{ listStyle: "none" }}>
            <li>
              <Link to="/">{t("header.home")}</Link>
            </li>
            <li>
              <Link to="/about">{t("header.about")}</Link>
            </li>
            <li>
              <Link to="/rooms">{t("header.rooms")}</Link>
            </li>
            <li>
              <Link to="/gallery">{t("header.gallery")}</Link>
            </li>
            <li>
              <Link to="/contact">{t("header.contact")}</Link>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={booking}
              >
                Booking.com
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.aristearentacar.gr/"
              >
                {t("header.rent")}
              </a>
            </li>
            <li>
              <i className="fas fa-phone"></i>
              <a href="tel:+302254024392"> +30 2254024392</a>
            </li>
            <li>
              <i className="fas fa-mobile"></i>
              <a href="tel:+306981583591"> +30 6981583591</a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>
              <a href="mailto:astronhotel@yahoo.com"> astronhotel@yahoo.com</a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default OverlayMenu;
