import React, { useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const ContactForm = ({ environment, dispatch }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isVerified] = useState(true);
  const [responseMessage, setResponseMessage] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSurnameChange = (event) => {
    setSurname(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate form fields
    if (
      name.trim() === "" ||
      surname.trim() === "" ||
      email.trim() === "" ||
      message.trim() === "" ||
      phone.trim() === "" ||
      subject.trim() === ""
    ) {
      alert(t("contact.alert1"));
      return;
    }

    if (!isVerified) {
      alert(t("contact.alert2"));
      return;
    }

    // Prepare the form data
    const formData = {
      name: name,
      surname: surname,
      email: email,
      phone: phone,
      subject: subject,
      message: message,
    };

    // Get the environment configuration
    // Get the API endpoint based on the environment
    const apiEndpoint =
      environment === "production"
        ? "https://info.rafkonapartments.gr/email/send/astron"
        : "http://localhost:8001/email/send/astron";

    if (!apiEndpoint) {
      console.error("Invalid environment configuration.");
      return;
    }

    // Make the API request
    fetch(apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + btoa('superUser:mySecretCombinationItsAMysteryForYou')
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API response:", data);
        if (data.code === 0) {
          setResponseMessage(t("contact.success"));
          // Additional actions for success response
        } else if (data.code === 400) {
          setResponseMessage(t("contact.warning"));
          // Additional actions for error response
        } else {
          setResponseMessage(t("contact.alert3"));
        }
      })
      .catch((error) => {
        console.error("API error:", error);
        // Handle the API error
        setResponseMessage(t("contact.alert3"));
      });
  };

  const responseMessageClass = classnames({
    alert: true,
    "alert-success": responseMessage === t("contact.success"),
    "alert-warning": responseMessage === t("contact.warning"),
    "alert-danger": responseMessage === t("contact.alert3"),
  });

  return (
    <form
      name="sentMessage"
      id="contact-form"
      className="contact-form"
      onSubmit={handleSubmit}
    >
      <div className="row form-group">
        <div className="col-md-6">
          <label htmlFor="name">{t("contact.name")}</label>
          <input
            type="text"
            id="name"
            className="form-control"
            data-validation-required-message="Please Add your Name"
            aria-required="true"
            required
            placeholder={t("contact.name")}
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="surname">{t("contact.surname")}</label>
          <input
            type="text"
            id="surname"
            className="form-control"
            data-validation-required-message="Please add your Last Name"
            aria-required="true"
            required
            placeholder={t("contact.surname")}
            value={surname}
            onChange={handleSurnameChange}
          />
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-6">
          <label htmlFor="email">{t("contact.email")}</label>
          <input
            type="email"
            id="email"
            className="form-control"
            data-validation-required-message="Please add your email"
            aria-required="true"
            required
            value={email}
            placeholder="Email"
            onChange={handleEmailChange}
          />
        </div>
        <div className="col-md-6">
          <label htmlFor="phone">{t("contact.phone")}</label>
          <input
            type="number"
            id="phone"
            value={phone}
            placeholder={t("contact.phone")}
            className="form-control"
            data-validation-required-message="Please add your phone number"
            aria-required="true"
            required
            pattern="\d*"
            maxLength="10"
            onChange={handlePhoneChange}
          />
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-12">
          <label htmlFor="subject">{t("contact.subject")}</label>
          <select
            name="subject"
            type="text"
            id="subject"
            className="form-control"
            onChange={handleSubjectChange}
          >
            <option>
              {t("contact.subject")}
            </option>
            <option value={t("contact.checkRoom")}>
              {t("contact.checkRoom")}
            </option>
            <option value={t("contact.prices")}>{t("contact.prices")}</option>
            <option value={t("contact.other")}>{t("contact.other")}</option>
          </select>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-12">
          <label htmlFor="message">{t("contact.message")}</label>
          <textarea
            id="message"
            placeholder={t("contact.message")}
            cols="30"
            rows="10"
            className="form-control"
            value={message}
            onChange={handleMessageChange}
          ></textarea>
        </div>
        <div
          data-sitekey="6LdrH80ZAAAAAFaDaHP0IH8YDAS9TXUKBG_jdpIB"
          className="g-recaptcha form-group text-center"
        >
          <input style={{marginTop:"10px"}}
            id="sendMessageButton"
            type="submit"
            value={t("contact.submit")}
            className="btn btn-primary"
          />
        </div>
        <div>
          {responseMessage && (
            <div className={responseMessageClass}>{responseMessage}</div>
          )}
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  environment: state.environment.environment,
});

export default connect(mapStateToProps)(ContactForm);
