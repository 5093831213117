import React from 'react';

const Map = () => {

    return (
        <div id="" className="">
        <iframe title="Location on Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d425.48754988933524!2d25.060816047928615!3d39.87724193255191!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14af85171a0c135b%3A0xcd240e8b9efc3082!2zzrHPg8-Ez4HOv869IM6-zrXOvc6_zrTPh861zrnOvw!5e0!3m2!1sen!2sgr!4v1546825078907"
                height="600" 
                style={{minWidth:0, width:"100%", maxWidth:"100%"}}
                allowFullScreen></iframe>
    </div>
    );
};

export default Map;
