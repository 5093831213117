import React from "react";
import { useTranslation } from 'react-i18next';
import ServicesInfo from "../partials/ServicesInfo";

const Rooms = () => {
  const { t } = useTranslation();
  return (
    <>
      <div id="astron-rooms" className="astron-light-grey">
        <div className="container">
          <div className="row">
            <div className="col-md-6 room-wrap animate-box">
              <a aria-label="" href="images/services/With-Balcony/1112.JPG"  className="room"
                style={{ backgroundImage: " url(images/services/With-Balcony/1112.JPG)" }}
              ></a>
              <div className="desc text-center">
                <h2><a href="services">{t("welcome.room1")}</a></h2>
                <span className="rate-star"><em className="icon-star-full full"></em><em className="icon-star-full full"></em><em className="icon-star-full full"></em></span>
                <p style={{textAlign:"center"}}>{t("welcome.room1d")}</p>
                <ServicesInfo></ServicesInfo>
              </div>
            </div>
            <div className="col-md-6 room-wrap animate-box">
              <a aria-label="" href="images/services/Two-Room/1111.JPG"  className="room"
                style={{ backgroundImage: " url(images/services/Two-Room/1111.JPG)" }}
              ></a>
              <div className="desc text-center">
                <h2><a href="services">{t("welcome.room3")}</a></h2>
                <span className="rate-star"><em className="icon-star-full full"></em><em className="icon-star-full full"></em><em className="icon-star-full full"></em></span>
                <p style={{textAlign:"center"}}>{t("welcome.room3d")}</p>
                <ServicesInfo></ServicesInfo>
              </div>
            </div>
            <div className="col-md-12 room-wrap animate-box">
              <a aria-label="" href="images/services/No-Balcony/1113.JPG"  className="room"
                style={{ backgroundImage: " url(images/services/No-Balcony/1113.JPG)" }}
              ></a>
              <div className="desc text-center">
                <h2><a href="services">{t("welcome.room2")}</a></h2>
                <span className="rate-star"><em className="icon-star-full full"></em><em className="icon-star-full full"></em><em className="icon-star-full full"></em></span>
                <p style={{textAlign:"center"}}>{t("welcome.room2d")} </p>
                <ServicesInfo></ServicesInfo>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Rooms;
