import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import ReactModalImage from 'react-modal-image';
Modal.setAppElement('#root');
const About = () => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <div id="astron-about" className="astron-light-grey">
                <div className="container">
                    <div className="row">
                        <div id="about" className="col-md-12">
                            <div className="col-md-6">
                                <div className="about animate-box">
                                    <h2>{t('footer.hotel')}</h2>
                                    <p>{t('about.p1')}</p>
                                    <p>{t('about.p2')}</p>
                                    <p>
                                        <span></span>{t('about.p3')}
                                        <a style={{cursor:"pointer"}} href aria-label='' onClick={openModal}>
                                            {t('about.catalog')}
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img
                                    className="img-responsive"
                                    src="images/about/37695312_10215215320083657_6754987519332319232_n.jpg"
                                    alt="About Hotel Astron"
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* React Modal */}
            <Modal
                isOpen={showModal}
                onRequestClose={closeModal}
                contentLabel="Modal"
                style={{
                    content: {
                        position: "absolute",
                        inset: "0px",
                        border: " 1px solid rgb(204, 204, 204)",
                        background: "#fff",
                        overflow: "auto",
                        borderRadius: "4px",
                        outline: "none",
                        maxWidth: "600px",
                        padding: "20px",
                        marginLeft: "auto",
                        marginRight: "auto"
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        zIndex:'10000',
                        inset: "18px",
                        
                    }
                }}
                        >
                        <div>
                            <ReactModalImage
                                small="images/about/1.jpg"
                                large="images/about/1.jpg"
                                alt="About Hotel Astron"
                            />
                            <ReactModalImage
                                small="images/about/2.jpg"
                                large="images/about/2.jpg"
                                alt="About Hotel Astron"
                            />
                            <ReactModalImage
                                small="images/about/3.jpg"
                                large="images/about/3.jpg"
                                alt="About Hotel Astron"
                            />
                            <button className="btn-primary" onClick={closeModal}>Close</button>
                        </div>
            </Modal >
        {/* End React Modal */ }
        </>
    );
};

export default About;
