import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default class Carousel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        // Simulating 2 seconds loading time
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 100);
    }

    handleImageLoad = () => {
        // Check if all images have loaded
        const allImagesLoaded = document.querySelectorAll('.img').length === this.props.images.length;

        if (allImagesLoaded) {
            // All images have loaded
            this.setState({ isLoading: false });
        }
    };

    render() {

        const settings = {
            loop: true,
            margin: 0,
            items: 1,
            dots: true,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn',
            smartSpeed: 1200,
            autoHeight: false,
            autoplay: true,
            mouseDrag: true
        };

        const imageUrls = [
            'images/carousel/carous1.jpg',
            'images/carousel/carous2.jpg',
            'images/carousel/carous3.jpg',
            'images/carousel/carous4.jpg',
            // Add more image URLs as needed
        ];

        return (
            <div id="astron-hero" >
                <OwlCarousel className="owl-theme" dots {...settings}>
                    {imageUrls.map((imageUrl, index) => (

                        <div id="image-carousel">
                            <img style={{ maxHeight: "1200px", height:"956px" }} className="img" src={imageUrl} alt={`Rafkon Slider ${index}`} />
                        </div>

                    ))}
                </OwlCarousel>
                <div class="slider-wrapper"></div>
            </div>
        );
    }
}
