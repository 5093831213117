import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const booking = "https://www.booking.com/hotel/gr/astron-murina-lemnos.".concat(language).concat(".html");

  return (
    <header className="astron-nav">
      <div className="top-menu">
        <div className="container">
          <div className="row">
            <div className="col-md-2">
              <div id="astron-logo">
                <Link to="/">
                  <img
                    alt="Logo Astron"
                    id="logo"
                    src="images/carousel/Logo.png"
                  />
                </Link>
              </div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                id="lemnos"
                href="https://el.wikipedia.org/wiki/%CE%9C%CF%8D%CF%81%CE%B9%CE%BD%CE%B1"
              >
                MYRINA-LEMNOS-GREECE
              </a>
            </div>
            <div id="navbar-list" className="col-md-10 text-center menu-1">
              <ul className="astron-social">
                <li>
                  <Link to="/">{t("header.home")}</Link>
                </li>
                <li>
                  <Link to="/rooms">{t("header.rooms")}</Link>
                </li>
                <li>
                  <Link to="/gallery">{t("header.gallery")}</Link>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={booking}
                  >
                    Booking.com
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.aristearentacar.gr/"
                  >
                    {t("header.rent")}
                  </a>
                </li>
                <li>
                  <Link to="/about">{t("header.about")}</Link>
                </li>
                <li>
                  <Link to="/contact">{t("header.contact")}</Link>
                </li>
                <li>
                  <div className="language-option">
                    <img src={`./images/icons/${language}.png`} alt="" />
                    <span>
                      {language.toUpperCase()}{" "}
                      <i className="fa fa-angle-down"></i>
                    </span>
                    <div className="flag-dropdown">
                      <ul>
                        <li>
                          <button
                            style={{
                              borderStyle: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={() =>
                              changeLanguage(language === "en" ? "gr" : "en")
                            }
                          >
                            {language === "en" ? "GR" : "EN"}
                            <span className="spanLanguage"></span>
                            <img
                              src={`./images/icons/${
                                language === "en" ? "gr" : "en"
                              }.png`}
                              alt=""
                            />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bookBackAbs">
        <div className="grid100">
          <div className="phoneTop">
            <ul style={{ listStyle: "none", margin: "0px 0px 0px -28px" }}>
              <li>
                <span className="fas fa-phone pr-2">
                  <a style={{color:"white"}} href="tel:+30 2254024392">2254024392</a>
                </span>
              </li>
              <li>
                <span className="fas fa-mobile pr-2">
                  <a style={{color:"white"}} href="tel:+30 6978334581">6978334581</a>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="grid100">
          <button className="btn-primary bookNow">
            <a id="bookNowanchor"
              href="tel:+30 2254024392"
              style={{ color: "#0b0600" }}
              type="submit"
            >
              {t("header.booking")}
            </a>
          </button>
        </div>
      </div>
    </header>
  );
};
export default Header;
