import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

const ServicesInfo = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    
    useEffect(() => {
        setLanguage(i18n.language);
      }, [i18n.language]);
    
    
    const booking = "https://www.booking.com/hotel/gr/astron-murina-lemnos.".concat(language).concat(".html");

  
    return (
        <ul>
            <li><img id="ac" alt="aircondition" src="images/icons/ac2-150x150.png" /> {t("services.aircon")} <em className="icon-check"></em></li>
            <li><img id="shower" alt="shower" src="images/icons/thin-0956_bath_shower-512-150x150.png" /> {t("services.bath")} <em className="icon-check"></em></li>
            <li><img id="dryer" alt="hair dryer" src="images/icons/gold-hair-dryer-512.gif" /> {t("services.dryer")} <em className="icon-check"></em></li>
            <li><img id="wifi" alt="wifi" src="images/icons/wifi_318-1573-150x150.jpg" /> {t("services.wifi")} <em className="icon-check"></em></li>
            <li><a rel="noopener noreferrer"
                target="_blank" className='btn btn-primary' href={booking}>
                {t("rooms.more")}</a></li>
        </ul>
    );
};

export default ServicesInfo;
