const initialState = {
  environment: "production",
};

const environmentReducer = (state = initialState, action) => {
  // Handle actions and update state accordingly
  switch (action.type) {
    case "SET_ENVIRONMENT":
      return {
        ...state,
        environment: action.payload,
      };
    default:
      return state;
  }
};

export default environmentReducer;
