import React, {useEffect, useState} from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    
    useEffect(() => {
        setLanguage(i18n.language);
      }, [i18n.language]);
    
    
    const booking = "https://www.booking.com/hotel/gr/astron-murina-lemnos.".concat(language).concat(".html");
  return (
  <div>
    <footer id="astron-footer"  style={{marginBotton:"-6rem"}}>
    <div className="container">
        <div className="row"  style={{border:"1px white", borderRadius: "10px" }} >
            <div className="col-md-12 animate-box text-center fadeInRight" >
                <div className="col-md-4 ">
                    <h4>{t("footer.hotel")}</h4>

                    <ul className="astron-footer-links">
                        <li><Link to="/rooms">{t("header.rooms")}</Link></li>
                        <li><Link to="/gallery">{t("header.gallery")}</Link></li>
                        <li><Link to="/about">{t("header.about")}</Link></li>
                        <li><Link to="/contact">{t("header.contact")}</Link></li>
                    </ul>
                </div>
                <div className="col-md-4 ">
                    <h4>{t("footer.links")}</h4>

                        <ul className="astron-footer-links">
                            <li><a target="_blank" rel="noopener noreferrer"
                                   href="https://www.tripadvisor.com.gr/Attractions-g189478-Activities-Lemnos_Northeast_Aegean_Islands.html">{t("footer.discover")}</a></li>
                            <li><a target="_blank" rel="noopener noreferrer"
                                   href="https://www.tripadvisor.com.gr/Restaurants-g189478-Lemnos_Northeast_Aegean_Islands.html">{t("footer.food")}</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Lemnos">{t("footer.history")}</a></li>
                            <li><a target="_blank" rel="noopener noreferrer" href="http://www.aristearentacar.gr/">{t("header.rent")}</a></li>
                            <li><a target="_blank" rel="noopener noreferrer"
                                   href={booking}>Booking.com</a>
                            </li>
                        </ul>

                </div>
                <div className="col-md-4 ">
                    <h4>{t("footer.contact")}</h4>

                        <ul className="astron-footer-links">
                            <li>
                    <a href="contact" style={{textAlign:"center"}}> {t("footer.address")}</a></li>
                    <li><strong style={{padding:"3px", color:"#FFFFFF"}} className="fas fa-mobile pr-2"></strong><a className="text-inform"
                                                                       style={{textAlign:"center"}}
                                                                       href="tel:+30 6978334581 "><span
                                   >+30 6978334581 |</span></a><a className="text-inform"
                                   style={{textAlign:"center"}}
                                                                                        href="tel:+30 2254024392 "><span
                                     className=""> +30 2254024392</span></a>
                    </li>
                    <li><a href="mailto:astronhotel@yahoo.com">astronhotel@yahoo.com</a></li>
                    </ul>

                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 animate-box text-center fadeIn" style={{paddingTop:"10px"}}>
                <div className="col-md-4 text-center">
                    <a target="_blank" rel="noopener noreferrer"
                       href="https://www.kayak.com/travelawards">
                    <img
                         alt="Kayak Travel Awards"style={{margin:"10px auto", height: "150px", width: "auto",  position: "relative", top:"-25px"}}
                         src="images/pages/DARK_MEDIUM_TRAVEL_AWARDS.png"/></a>
                </div>

                <div className="col-md-5 text-center" style={{paddingTop:"3rem"}}>
                    <p style={{textAlign:"center"}}>
                        <small style={{color:"white"}} className="block">
                            Copyright &copy; Astron Lemnos 2017 - {(new Date().getFullYear())} All rights reserved</small>
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>

  </div>
  )
};
export default Footer;
