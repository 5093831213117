import React from "react";
import { useTranslation } from 'react-i18next';
import Map from "../partials/Map";
import ContactForm from "../partials/ContactForm";

const Contact = () => {
    const { t } = useTranslation();
    return (
        <>
            <div id="astron-contact" className="astron-light-grey">
                <div id="contact" className="container">
                    <div className="row">
                        <div class="col-lg-12 animate-box">
                            <h3 style={{ paddingBottom: "1em" }}>{t('contact.info')}</h3>
                            <div id="contact" className="col-lg-12 animate-box">
                                <div className="row contact-info-wrap">
                                    <div className="col-md-6">
                                        <p><span><em className="icon-location-2"></em></span> {t('footer.address')}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p><span><em className="icon-phone3"></em></span> <a style={{ color: "#0b0600" }}
                                            href="tel:+30 6978334581"><span>{t('contact.call')}: +30 6978334581 |</span></a>
                                            <a style={{ color: "#0b0600" }}
                                                href="tel:+30 2254024392"><span
                                                    className="">+30 2254024392</span></a></p>
                                    </div>
                                    <div className="col-md-6">
                                        <p><span><em className="icon-paperplane"></em></span> <a style={{ color: "#0b0600" }} href="mailto:astronhotel@yahoo.com">astronhotel@yahoo.com</a>
                                        </p>
                                    </div>
                                    <div className="col-md-6" >
                                        <p><span><em style={{ color: "goldenrod", fontSize: "x-large", marginRight: "7px" }} className="fas fa-car pr-2"></em></span> <a rel="noopener noreferrer" target="_blank" style={{ color: " #0b0600" }}
                                            href="http://www.aristearentacar.gr/"> aristearentacar.gr</a></p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12  animate-box">

                            <h3>{t('contact.send')}</h3>
                            <ContactForm></ContactForm>

                        </div>
                    </div>
                </div>
            </div>
            <Map></Map>
        </>
    );
};

export default Contact;