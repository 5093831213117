import React from 'react';
import { RingLoader } from 'react-spinners';

const LoaderImages = () => {
    return (
        <div className="loader-container">
                <RingLoader color={["#dfa974"]} loading={true} size={150} />
        </div>
    );
};

export default LoaderImages;
